import { ResearchRibbonV2Component } from "gx-npm-ui";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { MarketProfileAppContext } from "../../../app.context";

const MarketResearchRibbonComponent = () => {
  const { t } = useTranslation();
  const { name, gcomMgResId, gcomMqResId, piMarketSeoName } = useContext(MarketProfileAppContext);
  const showAnimation = JSON.parse(localStorage.getItem("showRibbonAnimation") || "false");

  return (
    <ResearchRibbonV2Component
      autoExpand={showAnimation}
      backToText={t("MARKET")}
      magicQuadrantResId={gcomMqResId}
      marketGuideResId={gcomMgResId}
      piMarketSeoName={piMarketSeoName}
      templateName={name}
    />
  );
};

export default MarketResearchRibbonComponent;
